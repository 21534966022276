.layout {
  background-color: #309e97;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'Open Sans';
}

:root:root {
  --adm-color-primary: #37c2bb;
}

/* textarea:focus,
input:focus {
  color: #b1e7e5;
} */

input,
select,
textarea {
  color: #000;
}

::placeholder {
  color: rgba(177, 231, 229, 0.8) !important;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: rgba(177, 231, 229, 0.8) !important;
}
