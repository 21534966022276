* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

#root {
  height: 100vh;
}

html,
body {
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}
