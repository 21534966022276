.typographyBody {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  line-height: 1.2;
}

.typographySubTitle {
  font-size: 10px;
  font-weight: 300;
  color: #f1f1f1;
  line-height: 1.2;
  height: 24px;
}

.typographyTitle {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  line-height: 1.2;
}
